import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Typography,
} from "@mui/material";
import { BoxContent, CircularLoading, WarningMessageYellow } from "../../Atoms";
import {
  DropFileInput,
  GenericInput,
  GenericSelect,
  MessageBox,
  MessageIcon,
} from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { CustomModal } from "../Modal";
import { Validation } from "./validationSchema";
import { useCommonData } from "../../../../hooks/useCommonData";
import { CreateCreditIntance } from "../../../../types/credits";
import useCredits from "../../../../hooks/context/useCredits";
import { amber } from "@mui/material/colors";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import useToast from "../../../../hooks/context/useToast";
import { PaymentModalCreditCard } from "../../Molecules/PaymentModalCreditCard";

interface Props {
  creditID?: number | null | undefined;
  handleSubmit(val: any): void;
  isLoadingFiles: boolean;
  isLoading: boolean;
  verifyPayment(chargeID: string, orderID: number): void;
}

export function CreditBox({
  creditID,
  handleSubmit,
  isLoadingFiles,
  isLoading,
  verifyPayment,
}: Props) {
  const { setOpen, setToastData } = useToast();
  const { isSmalldesktop } = useBreakpoints();
  const { orderModal, paymentChoices } = useCommonData();
  const { getUserLocalStorage, language } = useAuth();
  const { validationSchema } = Validation(language);
  const { user } = getUserLocalStorage();
  const { credit, sendNewMessage, readAllMessages, setKeepAttMessages } =
    useCredits();
  const { JPYmoney, getPixValue } = useJPYmoney();
  const [messageModal, setMessageModal] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState(true);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [currencyBRL, setCurrencyBRL] = useState("0");
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);

  const messages = useMemo(
    () =>
      credit?.WarningMessage?.sort((a: IMessage, b: IMessage) =>
        a?.created < b?.created ? 1 : -1
      ),
    [credit?.WarningMessage]
  );

  const formik = useFormik<CreateCreditIntance>({
    initialValues: {
      creditValue: "",
      paymentChoice: "",
      files: [],
      userID: user?.user?.id,
    },
    validationSchema,
    async onSubmit(values) {
      const selectOrderType = () => {
        if (!creditID) return "create";
        if (credit?.status === "waitingPayment") return "updateFiles";
        return "update";
      };
      handleSubmit({
        files: values.files,
        creditValue: values.creditValue,
        paymentChoice: values.paymentChoice,
        userID: values.userID,
        creditID: creditID,
        orderType: selectOrderType(),
      });
      formik.resetForm();
    },
  });

  const limitSizeFiles = useMemo(() => {
    const verifyLimite =
      formik.values.files.reduce((acc: any, next: any) => acc + next?.size, 0) /
      1024;
    return verifyLimite;
  }, [formik.values.files]);

  useEffect(() => {
    // controla se o modal vai ser de cadastro ou edição
    if (credit) {
      formik.setFieldValue("userID", user.user.id ?? 0);
      formik.setFieldValue("creditValue", credit?.creditValue ?? "");
      formik.setFieldValue("paymentChoice", credit?.paymentChoice ?? "");
    }

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);
    // eslint-disable-next-line
  }, [credit, creditID]);

  useEffect(() => {
    if (creditID) {
      setKeepAttMessages(true);
    }

    return () => setKeepAttMessages(false);
    // eslint-disable-next-line
  }, []);

  const disableInputs = () => {
    if (creditID && user.user.role !== "admin") return true;
    return false;
  };

  const hasNewMessage = () => {
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const showBtn = () => {
    if (!creditID) return true;
    if (credit?.status === "waitingPayment" && credit?.paymentChoice !== "card")
      return true;
    return false;
  };

  const showCreditCardPaymentButton = () => {
    if (!creditID) return false;
    if (credit?.status === "waitingPayment" && credit?.paymentChoice === "card")
      return true;
    if (credit?.status === "notPaid" && credit?.paymentChoice === "card")
      return true;
    return false;
  };

  const getInfoPaymentChoice = () => {
    switch (formik.values.paymentChoice) {
      case "wise":
        return orderModal.wisePayment;
      case "paypal":
        return orderModal.paypalPayment;
      case "pix":
        return orderModal.pixPayment;

      default:
        return "";
    }
  };

  const calcPaymentTax = (credits: number) => {
    if (formik.values.paymentChoice === "pix") {
      return {
        tax: JPYmoney(credits * 0.06),
        total: JPYmoney(credits * 0.06 + Number(formik.values.creditValue)),
        onlyNumber: credits * 0.06 + Number(formik.values.creditValue),
      };
    }
    if (formik.values.paymentChoice === "paypal") {
      return {
        tax: JPYmoney(credits * 0.07),
        total: JPYmoney(credits * 0.07 + Number(formik.values.creditValue)),
        onlyNumber: credits * 0.07 + Number(formik.values.creditValue),
      };
    }
    if (formik.values.paymentChoice === "card") {
      return {
        tax: JPYmoney(Math.ceil(credits / 0.959 - credits)),
        total: JPYmoney(
          credits / 0.959 - credits + Number(formik.values.creditValue)
        ),
        onlyNumber:
          credits / 0.959 - credits + Number(formik.values.creditValue),
      };
    }
    return {
      tax: 0,
      total: JPYmoney(Number(formik.values.creditValue)),
      onlyNumber: 0,
    };
  };

  const getCurrency = async () => {
    setCurrencyLoading(true);
    try {
      const calcTotal = calcPaymentTax(
        Number(formik.values.creditValue)
      ).onlyNumber;
      if (calcTotal > 0) {
        const pix = await getPixValue(calcTotal);

        setCurrencyBRL(pix);
      }
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "Ocorreu um erro ao calcular o valor em Reais, tente novamente.",
      });
      setOpen(true);
      setCurrencyBRL("0");
    } finally {
      setCurrencyLoading(false);
    }
  };

  useEffect(() => {
    if (formik.values.paymentChoice === "pix") {
      getCurrency();
    }
    // eslint-disable-next-line
  }, [formik.values.paymentChoice, formik.values.creditValue]);

  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          {/* <WarningMessageYellow message={pixDisabledMessage} hasSpace={false} /> */}
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            {creditID && (
              <MessageIcon
                hasNewMessage={hasNewMessage()}
                openModal={() => setMessageModal(true)}
                tooltipOpen={messageTooltip}
                text={orderModal.tooltip}
                chatButton
              />
            )}

            <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
            {!creditID && (
              <WarningMessageYellow message={orderModal.warningCredits} />
            )}
          </Box>

          <GenericInput
            hasIcon={false}
            onChange={(value, name) =>
              formik.setFieldValue(name, Number(value))
            }
            error={
              formik?.touched?.creditValue && formik?.errors?.creditValue
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.creditValue ? formik?.errors?.creditValue : ""
            }
            value={formik?.values?.creditValue}
            placeholder={orderModal.creditValue}
            inputName="creditValue"
            inputType="number"
            disabled={disableInputs()}
          />

          <GenericSelect
            formWidth="98%"
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              formik?.touched?.paymentChoice && formik?.errors?.paymentChoice
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.paymentChoice
                ? formik?.errors?.paymentChoice
                : ""
            }
            value={formik?.values?.paymentChoice}
            placeholder={orderModal.payment}
            name="paymentChoice"
            options={paymentChoices}
            disabled={creditID ? true : false}
          />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"98%"}
            mb={1}
            mt={1}
          >
            <Typography>Tax</Typography>
            <Typography>
              {calcPaymentTax(Number(formik.values.creditValue)).tax}
            </Typography>
          </Box>
          <Divider sx={{ width: "98%" }} />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"98%"}
            mb={1}
            mt={1}
          >
            <Typography>Total</Typography>
            <Typography>
              {calcPaymentTax(Number(formik.values.creditValue)).total}
            </Typography>
          </Box>
          <Divider sx={{ width: "98%" }} />
          {formik.values.paymentChoice === "pix" && (
            <>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"98%"}
                mb={1}
                mt={1}
              >
                <Typography>BRL (reais)</Typography>
                {currencyLoading ? (
                  <CircularLoading />
                ) : (
                  <Typography>{currencyBRL}</Typography>
                )}
              </Box>
            </>
          )}

          {formik.values.paymentChoice !== "" &&
            formik.values.paymentChoice !== "card" && (
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                {getInfoPaymentChoice()}
              </FormHelperText>
            )}

          {formik.values.paymentChoice === "card" && !credit?.status && (
            <FormHelperText sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}>
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              The payment process will be available after we create the credit
              order, please click in create button bellow
            </FormHelperText>
          )}

          {formik.values.paymentChoice === "card" &&
            credit?.status === "paymentSent" && (
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                Waiting to store validade the payment
              </FormHelperText>
            )}

          {formik.values.paymentChoice !== "card" && (
            <>
              <Typography variant="h1" mb={3} mt={3}>
                {orderModal.proofPayment}
              </Typography>
              {limitSizeFiles >= 500 && (
                <Typography variant="h2" color={"red"} mb={1}>
                  Max file size reached
                </Typography>
              )}
            </>
          )}

          {isLoadingFiles && formik.values.paymentChoice !== "card" ? (
            <CircularLoading />
          ) : (
            formik.values.paymentChoice !== "card" && (
              <DropFileInput
                onFileChange={(files) => formik.setFieldValue("files", files)}
                oldFiles={credit?.code ? [{ code: credit?.code }] : []}
                filesQtd={1}
              />
            )
          )}
          {formik?.touched?.files && formik?.errors?.files ? (
            <FormHelperText error>{orderModal.errorFile}</FormHelperText>
          ) : (
            ""
          )}

          {credit?.status === "notPaid" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[600], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                The payment process was not concluded, please contact your card
                issuer or try again
              </FormHelperText>
            </>
          )}

          {credit?.status === "processingPayment" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                Payment has not been fully processed, please click below to
                verify payment
              </FormHelperText>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                onClick={() => verifyPayment(credit?.chargeID, credit?.id)}
              >
                Verify payment
              </Button>
            </>
          )}
          {showBtn() && (
            <Button
              sx={{ mt: 3 }}
              fullWidth
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              {formik.values.paymentChoice === "card" ? "create" : "send"}
            </Button>
          )}
          {showCreditCardPaymentButton() && (
            <Button
              sx={{ mt: 3 }}
              fullWidth
              variant="contained"
              onClick={() => setCreditCardModalOpen(true)}
            >
              Click here to pay with credit card
            </Button>
          )}

          <CustomModal
            openModal={messageModal}
            setOpenModal={setMessageModal}
            width={isSmalldesktop ? "90%" : "30%"}
          >
            <MessageBox
              data={messages}
              sendMessage={(newMessage) =>
                sendNewMessage({
                  description: newMessage,
                  orderID: credit?.id,
                  type: user.user.role,
                  userID: user.user.id,
                  messageType: "credits",
                })
              }
              readMessages={() => readAllMessages(credit?.id)}
            />
          </CustomModal>
        </>
      )}

      <PaymentModalCreditCard
        open={creditCardModalOpen}
        setOpen={() => setCreditCardModalOpen(false)}
        itensInfo={{
          itemID: creditID ?? 0,
          totalValue:
            Math.ceil(Number(formik.values.creditValue) / 0.959) -
            Number(formik.values.creditValue) +
            Number(formik.values.creditValue ?? 0),
          type: "credit",
        }}
      />
    </BoxContent>
  );
}
