import axios from "axios";

export function useJPYmoney() {
  const JPYmoney = (value: number) => {
    if (!value) return "￥ 0";
    const convert = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
    });
    return convert.format(value);
  };

  const BRLmoney = (value: number) => {
    if (!value) return "R$ 0";
    const convert = new Intl.NumberFormat("pr-BR", {
      style: "currency",
      currency: "BRL",
    });
    return convert.format(value);
  };

  const getPixValue = async (totalValue: number) => {
    const jpyXBRL = await axios.get(
      "https://economia.awesomeapi.com.br/last/JPY-BRL"
    );
    const ien = totalValue;
    const currency = jpyXBRL?.data?.JPYBRL?.ask;
    const result = ien * currency;

    const pix = result.toFixed(2);
    // console.log(Math.ceil(0.03768));
    // const pix = (formik.values.totalPrice * 0.03768).toFixed(2);
    return BRLmoney(Number(pix));
  };

  return { JPYmoney, BRLmoney, getPixValue };
}
